import React, { useEffect, useState } from "react";
import { banner2 } from "../../Assets.tsx";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText2", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg2", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  return (
    <div
      className="fullWidth"
      style={{ width: "100%", height: "100vh", background: "#f2f2f2" }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          color: "#0d803f",
          padding: "50px 0px",
        }}
        className="aboutus-title"
      >
        <span style={{ color: "#a6ce39" }}>About</span> Company
      </h1>

      <div
        style={{
          // borderBottom: "5px solid green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          color: "#0d803f",
          margin: "50px 0 50px 0px",
        }}
      >
        <span style={{ color: "#a6ce39" }}>About</span> Company
      </h1> */}
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
          className="about_us_page"
        >
          <div
            style={{ width: "46%", marginRight: "50px" }}
            className=" aboutus gsapAboutText2"
          >
            <img
              src={banner2}
              alt="about-us"
              style={{
                width: "700px",
                height: "450px",
                aspectRatio: "2",
                objectFit: "cover",
              }}
              className="fullWidth"
            />
          </div>
          <div
            style={{ width: "40%", marginBottom: "20px" }}
            className="about-us-paragraph gsapVishwasImg2"
          >
            <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
              <span
                style={{ fontWeight: 700, fontSize: "18px", color: "black" }}
              >
                {" "}
                Welcome to Green Gold Industries!
              </span>{" "}
              We are leading the way in agricultural innovation with our
              advanced chelated micronutrients that help crops grow better and
              support sustainable farming. We are starting with high-impact
              agricultural solutions, but we plan to expand into premium areas
              like agrochemicals, fertilizers, and food products by bringing
              everything together seamlessly.
            </p>
            <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
              Our products are designed to deliver essential minerals
              efficiently, ensuring your crops get the best care possible. We
              are committed to sustainability, helping boost agricultural
              productivity while protecting the planet.
            </p>
            <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
              As a global leader, we proudly export our solutions worldwide,
              promoting sustainable farming everywhere. With our focus on
              quality and innovation, we’re the perfect partner to help you
              succeed in agriculture.
            </p>
            <button
              onClick={() => history.push("/AboutUs")}
              className="glow-on-hover"
              type="button"
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
