import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar.tsx";
import Footer from "../Footer/Footer.tsx";
import { banner2, mission, promises, vission } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutusPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .CompanyTitle", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapAboutSection .CompanyTitle",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .gsapParagraph", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapAboutSection .gsapParagraph",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .sectionsPromises", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapAboutSection .sectionsPromises",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    gsap.from(".gsapAboutUs", {
      y: -30,
      opacity: 0,
      duration: 3,
      stagger: 0.3,
      delay: 1,
    });
  });

  return (
    <div style={{ width: "100%", height: "auto" }} className="fullWidth">
      <Navbar />
      {/* <div style={{ position: "relative", width: "100%" }} className="gsapAboutUs">
  <img
    src={banner2}
    alt="Banner"
    style={{ width: "100%", height: "100vh" }}
  />
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#36f236",
      fontSize: "60px",
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      padding: "10px 20px",
      borderRadius: "5px",
      width: "80%",
      height: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <button
  onClick={() => history.push("/")}
        style={{
        position: "absolute",
        left: "20px",
        top: "20px",
        background: "green",
        border: "1px solid black",
        color: "white",
        borderRadius:"5px",
        fontSize: "18px",
        cursor: "pointer",
      }}
    >
      Back
    </button>
    About Us
  </div>
</div> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
          marginTop: "70px",
        }}
        className="productSection fullWidth"
      >
        <div
          style={webStyle.paragraphboxShadow}
          className="gsapAboutSection missionVissionPadding"
        >
          <p
            className="CompanyTitle"
            style={{ fontSize: "40px", fontWeight: 700, color: "green" }}
          >
            {" "}
            <span style={{ color: "black" }}>Welcome to </span> Green Gold
            Industries!
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            At{" "}
            <span style={{ fontSize: "19px", fontWeight: 700, color: "black" }}>
              Green Gold Industries
            </span>{" "}
            , we're pioneering the future of agriculture with our cutting-edge
            chelated micronutrients, meticulously designed to optimize growth
            and drive sustainable cultivation. Our mission is to revolutionize
            the Agro Segment by initially focusing on high-impact solutions for
            agricultural growth and gradually expanding into premium agro-based
            sectors, including agrochemicals, fertilizers, and food products. We
            achieve this through a seamless integration of backward and forward
            processes, ensuring comprehensive solutions at every stage.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Our advanced products are engineered to deliver essential minerals
            with unparalleled efficiency, providing your crops with the precise
            care they need. We're passionate about sustainability and committed
            to enhancing agricultural productivity while nurturing a healthier
            planet.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Beyond local markets,{" "}
            <span style={{ fontSize: "18px", fontWeight: 700, color: "black" }}>
              Green Gold Industries
            </span>{" "}
            is a global leader, exporting our innovative solutions to support
            agricultural sustainability worldwide. Our international presence
            reflects our dedication to advancing global farming practices and
            delivering top-tier micronutrient solutions wherever they are
            needed.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Driven by innovation, quality, and global service, Green Gold
            Industries is your trusted partner for achieving thriving and
            sustainable farming practices around the world. Connect with us to
            discover how our expertise can support your agricultural success.
          </p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
        }}
        className="productSection fullWidth"
      >
        <div
          style={webStyle.paragraphboxShadow}
          className="gsapAboutSection missionVissionPadding"
        >
          <div
            style={{ display: "flex", color: "black" }}
            className="sectionsPromises about_us_page"
          >
            <div>
              {/* <img
                src={vission}
                alt="mission"
                style={{
                  width: "500px",
                  height: "auto",
                  borderRadius: "20px",
                }}
                className="fullWidth"
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginLeft: "30px",
              }}
              className="about_us_page promisesDiv"
            >
              <h1 style={{ color: "green", fontSize: "40px", fontWeight: 700 }}>
                Vision
              </h1>
              <p style={{ color: "grey", fontSize: "18px" }}>
                At{" "}
                <span
                  style={{ fontSize: "19px", fontWeight: 700, color: "black" }}
                >
                  Green Gold Industries
                </span>{" "}
                , we aspire to be the leading force in agricultural innovation,
                dedicated to providing groundbreaking, sustainable solutions
                that significantly enhance plant growth. Our vision is to
                revolutionize the way agriculture is practiced, ensuring that
                every product we develop not only maximizes crop yield but also
                promotes ecological balance and environmental health.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                We envision a future where our advanced chelated micronutrients
                and agrochemical solutions empower farmers worldwide to
                cultivate sustainably, utilizing practices that safeguard
                natural resources while meeting the growing global food demand.
                By fostering a culture of continuous improvement and innovation,
                we aim to set new standards in agricultural excellence, ensuring
                that our products contribute to healthier soils, resilient
                ecosystems, and thriving communities.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                Through our commitment to sustainability, we strive to create a
                greener world, where agriculture and nature coexist
                harmoniously. Our goal is to inspire and lead the industry in
                adopting practices that prioritize environmental stewardship,
                economic viability, and social responsibility, paving the way
                for a more sustainable future for generations to come.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
        }}
        className="productSection fullWidth"
      >
        <div
          style={webStyle.paragraphboxShadow}
          className="gsapAboutSection missionVissionPadding"
        >
          <div
            style={{
              display: "flex",
              color: "black",
              flexDirection: "row-reverse",
            }}
            className="sectionsPromises about_us_page"
          >
            <div>
              {/* <img
                src={mission}
                alt="mission"
                style={{
                  width: "500px",
                  height: "auto",
                  borderRadius: "20px",
                }}
                className="fullWidth"
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginRight: "30px",
              }}
              className="about_us_page missionDiv"
            >
              <h1 style={{ color: "green", fontSize: "40px", fontWeight: 700 }}>
                Mission
              </h1>
              <p style={{ color: "grey", fontSize: "18px" }}>
                At{" "}
                <span
                  style={{ fontSize: "19px", fontWeight: 700, color: "black" }}
                >
                  Green Gold Industries
                </span>{" "}
                , our mission is to deliver premium-quality chelated
                micronutrients that empower farmers, institution and industries
                to maximize crop yields while enhancing soil health. We are
                dedicated to developing innovative products that address the
                diverse needs of modern agriculture, ensuring that every farmer
                has access to the tools necessary for successful cultivation. By
                providing advanced formulations that optimize nutrient
                absorption, we help farmers achieve higher yields and healthier
                plants, contributing to food security and economic prosperity.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                Our commitment extends beyond crop production, we focus on
                sustainable practices that enrich soil quality, promote
                biodiversity, and maintain the ecological balance essential for
                long-term agricultural success. We believe in environmentally
                responsible farming, designing our products to minimize negative
                impacts on the environment and support practices that conserve
                resources and protect ecosystems. As a trusted partner in
                agriculture, we strive to meet the challenges faced by farmers
                worldwide, offering education, support, and innovative solutions
                tailored to local conditions.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                {" "}
                Through collaboration with agricultural stakeholders,
                researchers, and organizations, we aim to advance sustainable
                farming practices and drive the industry forward. Ultimately, we
                are committed to creating a more sustainable future for
                agriculture, where high-quality inputs lead to thriving farms,
                healthy ecosystems, and a greener planet for generations to
                come.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
        }}
        className="productSection fullWidth"
      >
        <div
          style={webStyle.paragraphboxShadow}
          className="gsapAboutSection missionVissionPadding"
        >
          <div
            style={{ display: "flex", color: "black" }}
            className="sectionsPromises about_us_page"
          >
            <div>
              {/* <img
                src={promises}
                style={{
                  width: "500px",
                  height: "auto",
                  borderRadius: "20px",
                }}
                className="fullWidth"
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginLeft: "30px",
              }}
              className="about_us_page promisesDiv"
            >
              <h1 style={{ color: "green", fontSize: "40px", fontWeight: 700 }}>
                Promises
              </h1>
              <p style={{ color: "grey", fontSize: "18px" }}>
                At <span
                  style={{ fontSize: "19px", fontWeight: 700, color: "black" }}
                >
                  Green Gold Industries
                </span>{" "}, we promise to provide innovative,
                sustainable products that boost agricultural productivity while
                caring for the environment. We believe that farming and
                sustainability can go hand in hand. Our goal is to create
                advanced chelated micronutrients and agrochemicals that not only
                help crops grow better but also improve soil health and support
                nature.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                We are committed to using eco-friendly materials and practices
                in everything we do. By investing in research, we aim to offer
                the best solutions to the challenges farmers face today.
              </p>
              <p style={{ color: "grey", fontSize: "18px" }}>
                Our promise includes building strong relationships with our
                customers, providing education and support to help them adopt
                sustainable practices. We envision a future where farming
                thrives without harming the planet, and we are dedicated to
                leading this important change. Together, we can create a healthy
                agricultural landscape that benefits both people and the
                environment for years to come.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const webStyle = {
  paragraphboxShadow: {
    width: "80%",
    height: "auto",
    backgroundColor: "white",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.2)",
    margin: "40px",
    padding: "40px",
    borderRadius: "8px",
  },
};

export default AboutusPage;
