import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from "@mui/icons-material/X";
import { useHistory } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { footerBanner, logo, potato, tomato } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const openInNewTab = () => {
    window.open(
      "https://www.google.com/maps/place/GREEN+GOLD+INDUSTRIES/@18.2655614,73.9093776,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2f3d20e6e259b:0x16f58af3520be46f!8m2!3d18.2655614!4d73.9093776!16s%2Fg%2F11q2lx6rh_?entry=ttu&g_ep=EgoyMDI0MTAyMi4wIKXMDSoASAFQAw%3D%3D",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const history = useHistory();

  const handleContact = () => {
    window.location.href = "tel:9834050550";
  };
  const handleWhatsApp = () => {
    const phoneNumber = "+919834050550";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  const handleEmail = () => {
    window.location.href = "mailto:greengoldindustries2020@gmail.com";
  };

  useGSAP(() => {
    if (!isMobile) {

    gsap.from(".gsapFooterSection", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".gsapFooterSection",
        scroller: "body",
        markers: false,
        start: "top 80%",
      },
    });
  }
  });

  useGSAP(() => {
    if (!isMobile) {

    gsap.from(".gsapFooterSection2", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapFooterSection2",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  }
  });

  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".gsapFooterLogo", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapFooterLogo",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  }
  });

  return (
    <div
      style={{
        ...webStyle.Container,
        flexDirection: "column",
        position: "relative",
      }}
      className=" fullWidth"
    >
      <div className=" fullWidth">
        <div>
          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

          <img
            src={logo}
            style={webStyle.logo}
            onClick={() => {
              history.push("/");
            }}
            className="gsapFooterLogo"
            />
            </div>

          <div style={webStyle.childContainer} className="FooterContainer gsapFooterSection">
            <div
              style={{
                width:"100%",
                display:"flex",
                alignContent:"center",
                justifyContent:"space-evenly",
                textTransform: "capitalize",
              }}
              className="FooterSection"
              
            >
              <div style={{display:"flex"}} className="FooterSection" >

              <span style={webStyle.title}>FOLLOW US ON</span>
              <div style={{marginLeft:"30px"}} className="callUsFooter">
                <FacebookIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <InstagramIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <TwitterIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <XIcon
                  className="RouteOptions2"
                  style={{ color: "black", fontSize: "30px" }}
                />
              </div>
              </div>
              <div style={{display:"flex",marginLeft:"20px",borderLeft:"2px solid grey",borderRight:"2px solid grey",padding:"0px 20px"}} className="FooterSection callUsFooter">

              <span style={{...webStyle.contactTitle}} onClick={handleContact}>call us </span>
              <span
                style={{...webStyle.contactText,marginLeft:"20px"}}
                className="RouteOptions2 callUsFooter"
                onClick={handleContact}
                >
                +91 9834050550
              </span>
                </div>
                <div style={{display:"flex",marginLeft:"20px",padding:"0px 20px"}} className="FooterSection callUsFooter">

              <span style={{...webStyle.contactTitle}} onClick={handleWhatsApp}>Whatsapp us </span>
              <span
                style={{...webStyle.contactText,marginLeft:"8px"}}
                className="RouteOptions2 callUsFooter"
                onClick={handleWhatsApp}
                >
                 +91 9834050550
              </span>
                </div>
            </div>
          </div>
          <div style={webStyle.childContainer} className="FooterContainer gsapFooterSection">
            <div
              style={{
                width:"100%",
                display:"flex",
                textTransform: "capitalize",
              }}
              className="FooterSection"
              
            >
              <div style={{display:"flex"}} className="FooterSection" >

              <span onClick={handleEmail} style={webStyle.title}>Email Us</span>
              <div onClick={handleEmail} style={{...webStyle.contactText,marginLeft:"100px",textTransform:"lowercase"}} className="RouteOptions2 callUsFooter">
              greengoldindustries2020@gmail.com

              </div>
              </div>
            </div>
          </div>
            <div
              style={{ display: "flex",
                padding: "30px 100px 0px 100px",}}
              className="FooterSection gsapFooterSection locationContainer"
            >
              <span style={webStyle.title}> Address</span>
              <a
              className="emailDiv"
                style={{ textDecoration: "none", color: "black",marginLeft:"100px" }}
                target="_blank"
                href="https://www.google.com/maps/place/GREEN+GOLD+INDUSTRIES/@18.2655614,73.9093776,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2f3d20e6e259b:0x16f58af3520be46f!8m2!3d18.2655614!4d73.9093776!16s%2Fg%2F11q2lx6rh_?entry=ttu&g_ep=EgoyMDI0MTAyMi4wIKXMDSoASAFQAw%3D%3D"
              >
                <div
                  style={{ width: "85%", display: "flex" }}
                  className="RouteOptions2"
                >
                  <LocationOnIcon />
                  <div className="RouteOptions2">
                    <span style={{ fontSize: "18px" }}>
                      Gate No-204,At-Post Karendi Kheba, Tal-Bhor, Dist-Pune Pincode-412213.
                    </span>
                  </div>
                </div>{" "}
              </a>
            </div>
            <div style={webStyle.childContainer} className="FooterContainer gsapFooterSection">
            <div
              style={{
                width:"100%",
                display:"flex",
                textTransform: "capitalize",
              }}
              className="FooterSection"
              
            >
              <div style={{display:"flex"}} className="FooterSection" >

                
                  <div className="map-container">
                    <div className="map-overlay" onClick={openInNewTab}></div>
                    <iframe
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAAdnoL9ZLaHUAZQ0z1QDjgQWXJCkFlCuE&q=7W85+6QC,+Karandi,+Maharashtra+412213"
                  width="100%"
                      height="150px"
                      style={{ border: 0, borderRadius: "5px" }}
                      loading="lazy"
                      title="Google Map"
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
          
          <div className="wheat-icons">
            <img src={tomato} alt="leaf1" className="wheat-icon wheat-icon1" />
            <img src={tomato} alt="leaf2" className="wheat-icon wheat-icon2" />
            <img src={tomato} alt="leaf3" className="wheat-icon wheat-icon3" />
            <img src={potato} alt="leaf4" className="wheat-icon wheat-icon4" />
          </div>
        </div>
      </div>
      <p
        className="copyRightstyle"
        style={{
          ...webStyle.copyRightText,
          position: "relative",
          textAlign: "center",
        }}
      >
        2024 © Green Gold Industries Pvt. Ltd. All rights reserved.
      </p>
    </div>
  );
};

const webStyle = {
  copyRightText: {
    width: "100%",
    paddingBottom: "30px",
    top: "100px",
    color: "gray",
    fontWeight: 400,
    fontSize: "12px",
  },
  logo: {
    width: "400px",
    marginBottom: "60px",
    cursor: "pointer",
    // border: "1px solid black",
    borderRadius: "8px",
  },
  Container: {
    height: "100vh",
    width: "100%",
    background: `url(${footerBanner}) no-repeat center center/cover`,
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px 20px",
    overflow: "hidden",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
  },
  contactTitle: {
    fontWeight: 600,
    color: "black",
    fontSize: "18px",
  },
  contactText: {
    fontWeight: 600,
    color: "black",
    cursor: "pointer",
  },
  socialMediaIcon: {
    color: "black",
    marginRight: "10px",
    fontSize: "30px",
  },
  SectionsContainer: {
    display: "flex",
    lineHeight: 1.5,
    // width: "100%",
  },
  childContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "30px 100px 0px 100px",
  },
};

export default Footer;
