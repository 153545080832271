import React, { useEffect, useState } from "react";
import "../Our Team/Team.css";
import { Team1, Team2, Team3} from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Team = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapTeamSection .gsapCard2", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapTeamSection .gsapCard2",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapTeamSection .teamsHeading", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapTeamSection .teamsHeading",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapTeamSection .gsapCard1", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapTeamSection .gsapCard1",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });

    }
  });


 
  return (
    <div className="TeamImageBg gsapTeamSection" id="our-team">
      <h1
        style={{
          ...webStyle.Heading,
          textTransform: "capitalize",
          textAlign: "center",
        }}
        className="teamsHeading"
      >
        <span style={{ color: "#bed630" }}>Our </span>Team
      </h1>
      <p className="teamsHeading" style={{ ...webStyle.subHeading, textAlign: "center" }}>
      Our Expertise Will Help You
      </p>
      <div style={{...webStyle.cardsParentContainer,padding:"20px 0px",flexWrap:"wrap"}}>
      <div style={webStyle.card} className="CardsStyles gsapCard1 OurTeamImages ">
          {/* <img
          src="https://t3.ftcdn.net/jpg/06/83/01/76/360_F_683017696_p2RfGFBwQebTO1VvMnA5NXQeqIskEfWe.jpg"
            style={webStyle.imageSize}
            className="OurTeamImagesSize OurTeamImages"
          /> */}
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
             <p style={webStyle.NameStyle}>
             Mr. Rajendra Markad
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            Chairman and executive director
            </span>
          </div>
        </div>
        <div style={webStyle.card} className="CardsStyles  OurTeamImagesMargin gsapCard2 OurTeamImages">
          {/* <img
            src="https://t3.ftcdn.net/jpg/06/83/01/76/360_F_683017696_p2RfGFBwQebTO1VvMnA5NXQeqIskEfWe.jpg"
            style={webStyle.imageSize}
            className="OurTeamImagesSize "
          /> */}
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
             <p style={webStyle.NameStyle}>
             Mr. Akshay Markad
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            Marketing Head
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const webStyle = {
  Heading: {
    paddingTop: "40px",
    fontSize: "50px",
    fontWeight: 700,
    color: "#00A550",
    marginBottom: "10px",
  },
  subHeading: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: 700,
  },
  card: {
    background: "white",
    height: "250px",
    width:"400px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    display: "flex",
    borderRadius:"8px "
  },
  imageSize: {
    width: "50%",
    // height:"365px"
  },
  cardsParentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap:"wrap"
  },
  CardsInfosection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 8px",
    width:"100%",
  },
  NameStyle: {
    fontSize: "20px",
    color: "black",
    fontWeight: 500,
  },
};

export default Team;
